<template>
  <div class="teamDetail">
    <div class="title_img">
      <img src="../../assets/image/about/detail/team_title.png" alt="" />
    </div>
    <div class="title">师资团队</div>
    <div class="bottom">
      <img src="@/assets/image/home/home_bottom.png" alt="" />
    </div>
    <div class="team_wrap" v-if="teamItem">
      <div class="team_avtar_filter">
        <img :src="teamItem.effectsUrl" alt="" />
      </div>
      <div class="team_avtar">
        <img :src="teamItem.avatarUrl" alt="" />
      </div>
      <div class="team_intro">
        <div class="intro_name">{{ teamItem.teacherName }}</div>
        <div class="intro_desc" v-html="teamItem.teacherIntro"></div>
      </div>
      <div class="team_lat">
        <img src="../../assets/image/about/about_lat.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamItem: {},
    };
  },
  mounted() {
    this.teamItem = this.$route.query.item;
  },
};
</script>

<style lang="less" scoped>
.teamDetail {
  width: 1760px;
  height: 927px;
  margin: auto;
  background: url("../../assets/image/about/detail/team_bg.png") center center
    no-repeat;
  background-size: 100% 100%;
  padding-top: 35px;
  position: relative;

  .title_img {
    width: 1152px;
    height: 87px;
    margin-left: 29px;
  }

  .title {
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
    padding-left: 29px;
    padding-top: 19px;
  }
  .bottom {
    position: absolute;
    width: 1627px;
    height: 69px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 45px;
  }

  .team_wrap {
    position: absolute;
    width: 100%;
    height: 363px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    // background-color: rebeccapurple;

    .team_lat {
      position: absolute;
      width: 113px;
      height: 55px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .team_avtar_filter {
      position: absolute;
      width: 316px;
      height: 316px;
      top: 0;
      left: 29px;
      background-color: transparent;
    }

    .team_avtar {
      position: absolute;
      width: 316px;
      height: 316px;
      bottom: 0;
      left: 123px;
      // background-color: red;
    }

    .team_intro {
      position: absolute;
      width: 901px;
      height: 100%;
      left: 508px;
      top: 0;
      background: url("../../assets/image/about/detail/team_detail_bg.png")
        center center no-repeat;
      background-size: 100% 100%;

      .intro_name {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 32px;
        padding-left: 67px;
        padding-top: 71px;
      }

      .intro_desc {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        padding-left: 67px;
        padding-top: 33px;
        padding-right: 67px;
      }
    }
  }
}
</style>